import disbursementApi from '@admin/api/endpoints/disbursement-approval';

export default {
	state: {
		data: {},
	},
	mutations: {
		updateDisbursementTable(state, tableData) {
			state.data = tableData;
		},
	},
	actions: {
		async getDisbursementApprovalTable({ commit }, page) {
			const res = await disbursementApi.getDisbursementApprovalTable(page);
			const {data} = res.data;
			const {pagination} = res.data;
			commit('updateDisbursementTable', { data, pagination });

			return res.data;
		},
	},
};
