import api from '@admin/api/service';

export default {
  getDisbursementApprovalTable(params = '') {
    return api.get(
      `/singgahsini/api/income/approval-property-disbursement?${params}`
    );
  },
  postDisbursementApproval(id) {
    return api.post(
      `/singgahsini/api/income/approval-property-disbursement/${id}/approve`
    );
  },
  postDialogBox(id, note, type) {
    return api.post(
      `singgahsini/api/income/approval-property-disbursement/${id}/${type}`,
      {
        notes: note,
      }
    );
  },

  approveContract(approvalId) {
    return api.post(
      `/singgahsini/api/income/approval-property-disbursement/${approvalId}/approve`
    );
  },

  unApproveContract(approvalId) {
    return api.post(
      `/singgahsini/api/income/approval-property-disbursement/${approvalId}/unapprove`
    );
  },
};
